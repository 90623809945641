<template>
  <div class="text-center">
    <v-dialog
      v-model="openDialog"
      width="500"
      height="500"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>Description Form </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="poultrydesc.animaldesc"
                label="Description"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="sel.length"
            small
            color="info"
            outlined
            rounded
            text
            @click="updatepoultry"
          >
            <v-icon>mdi-content-save</v-icon> Update
          </v-btn>
          <v-spacer />

          <v-btn
            v-if="!sel.length"
            small
            outlined
            rounded
            text
            color="success"
            @click="saveNewpoultry"
          >
            <v-icon>mdi-content-save</v-icon>
            Save as New Record
          </v-btn>
          <v-btn
            v-if="sel.length"
            small
            outlined
            rounded
            text
            color="error"
            @click="deletepoultry"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            text
            @click="closeDialog"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {

    props: ['openDialog', 'sel'],
    data: () => ({
      dialog: false,
      poultrydesc: {
        animaldesc: '',
        animalclass_id: '1',
      },
    }),

    watch: {
      sel: function (val) {
        if (val.length) {
          this.poultrydesc = val[0]
        } else {
          return val
        }
      },
    },

    mounted () {
    // this.poultrydesc.animaldesc=''
    },

    methods: {
      closeDialog () {
        this.$emit('closeDlg', false)
      },
      saveNewpoultry () {
        this.$store.dispatch('poultrydesc/postData', this.poultrydesc)
        this.$toast.success('Save Complete')
        this.closeDialog()
      },
      async deletepoultry () {
        const res = await confirm('Delete Data')
        if (res) {
          this.$store.dispatch('poultrydesc/deleteData', this.poultrydesc.id)
          this.$toast.success('Delete Complete')
        } else {
          this.$toast.info('Delete Cancelled')
        }
        this.closeDialog()
      },
      async updatepoultry () {
        await this.$store.dispatch('poultrydesc/updateData', this.poultrydesc)
        this.$toast.success('Update Complete')
        this.closeDialog()
      },
    },
  }
</script>
