<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="300"
      tile
    >
      <poultryvarietydialog
        :open-dialog="EditDialog"
        :sel="selected"
        @closeDlg="CloseDialog"
      />
      <v-card-title>
        Variety

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
        />
      </v-card-title>
      <v-card-text>
        <v-fab-transition>
          <v-btn
            color="warning"
            fab
            dark
            small
            absolute
            bottom
            right
            @click="dialog()"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="poultryvariety"
          :items-per-page="10"
          single-select
          show-select
          item-key="id"
          class="elevation-1"
          flat
          dense
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import poultryvarietydialog from './poultryvarietydialog.vue'

  export default {

    components: {
      poultryvarietydialog,
    },
    data: () => ({
      EditDialog: false,
      poultryvariety: [],
      search: null,
      selected: [],
      headers: [
        {
          text: 'Poultry Variety',
          value: 'animalvariety',
        },
      ],
    }),

    watch: {
      '$store.state.poultryvariety.data': function (val) {
        if (val.length > 0) {
          this.poultryvariety = val
        }
      },
    },

    methods: {
      async getpoultryvariety () {
        // run get api function
        this.$store.dispatch(
          'poultryvariety/getData',
          this.$store.poultrydesc.selected,
        )

        // set data from vuex storage
        this.poultryvariety = await this['poultryvariety/poultryvarietydata']
      },
      dialog () {
        this.EditDialog = true
      },
      CloseDialog (value) {
        this.EditDialog = value
        this.selected = []
      },
    },
  }
</script>
