<template>
  <div>
    <v-row>
      <v-col>
        <poultrydesc />
      </v-col>
      <v-col>
        <poultryvariety />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import poultrydesc from './poultrydesc.vue'
  import poultryvariety from './poultryvariety.vue'

  export default {
    components: {
      poultrydesc,
      poultryvariety,
    },
  }
</script>
